.form-group
  display: block
  // margin-bottom: ($rhythmic-unit/2)

.form-control-container
  position: relative
  margin-bottom: 10px

.form-control
  font-family: $font-main
  outline: 0
  color: $color-white
  border-radius: 2px
  padding: 5px 15px
  height: 56px
  line-height: 35px
  background-color: rgba(48, 48, 57, 0.8);
  width: 100%
  border: 0
  border-bottom: 1px solid $color-gray
  -moz-appearance: textfield

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0

  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button
    visibility: hidden
    display: none !important
    pointer-events: none
    position: absolute
    right: 0

  &:-webkit-autofill
    /* Change the color to your own background color */
    -webkit-box-shadow: 0 0 0 50px #fff inset
    -webkit-text-fill-color: #000

  &:focus
    outline: 0
    border-bottom: 1px solid $color-blue

  &:disabled
    background-color: $color-gray-light

textarea
  font-family: $font-main
  outline: 0
  padding: 5px 10px
  border-radius: 2px
  background-color: #fff
  border: 1px solid $color-gray-light
  resize: none
  -ms-overflow-style: none

  &::-webkit-scrollbar
   display: none


  &:focus
    outline: 0
    border: 1px solid $color-blue

  &:disabled
    background-color: $color-gray-light

select
  border: 1px solid $color-gray-light
  background-color: #fff
  border-radius:2px
  height: 35px
  padding: 5px
  line-height: 25px

select[multiple]
  height: auto
  min-height: 35px

input[type="radio"],
input[type="checkbox"]
  display: inline-block
  vertical-align: middle
  width: 16px
  height: 16px

input[type="radio"],
input[type="checkbox"]
  & + label
    display: inline-block
    vertical-align: middle
    margin-bottom: 0

  select,
  .select
    border: 1px solid $color-error


ul.errorlist
  list-style: none
  margin: 0
  padding: 0
  color: $color-error
  li
    padding: 0
    margin: 0
