/*
 *
<table class="table table--responsive">
  <tbody>
    <tr>
      <td title="Title">
        <div class="cell">
          Table content
        </div>
      </td>
    <tr>
    ...
  </tbody>
</table>
*
*/

.table
  width: 100%
  th
    font-weight: bold

  th,
  td
    padding: 10px 5px
    border-bottom: 1px solid $color-gray-lighter

  tbody
    tr
      &:nth-child(odd)
        & > th,
        & > td
          background-color #fff

      &:nth-child(even)
        & > th,
        & > td
          background-color $color-gray-lightest

.table--responsive
  display: block

  tr
    display: block

  th,
  td
    display: block
    width: auto
    &:before
      content:attr(title)
      font-weight: bold
      display: block

  thead
    display: block

  tbody
    display: block

.table--mb-hidden-header
  thead
    display: none

@media screen and ( min-width: $screenTabletPortrait )
  .table--responsive
    th,
    td
      &:before
        display: inline-block
        vertical-align: top

@media screen and ( min-width: $screenTablet )
  .table
    th,
    td
      vertical-align: top

  .table--responsive
    display: table
    tr
      display: table-row

    th,
    td
      display: table-cell
      &:before
        display: none

    thead
      display: table-header-group

    tbody
      display: table-row-group

  .table--mb-hidden-header
    thead
      display: table-header-group

  .table--layout-fixed
    table-layout: fixed

  .table--no-border
    tbody
      tr
        & > th,
        & > td
          border-bottom: 0
