@charset "utf-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background-color: transparent;
  font-size: 100%;
}
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline: 0;
}
a:focus {
  outline: thin dotted;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
td img {
  vertical-align: top;
}
input,
select,
button,
textarea {
  margin: 0;
  font-size: 100%;
}
button,
select {
  text-transform: none;
}
input[type="text"],
input[type="password"],
textarea {
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  vertical-align: middle;
  margin: 0;
  padding: 0;
  box-sizing: border-box /* 1 */;
  padding: 0 /* 2 */;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button /* 2 */;
  cursor: pointer /* 3 */;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type="search"] {
  -webkit-appearance: textfield /* 1 */;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box /* 2 */;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
  outline: 1px dotted ButtonText;
}
::-webkit-file-upload-button {
  -webkit-appearance: button /* 1 */;
  font: inherit /* 2 */;
}
textarea {
  overflow: auto /* 1 */;
  vertical-align: top /* 2 */;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
summary {
  display: list-item;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
nav ul {
  list-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
small {
  font-size: 80%;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
.icons {
  display: inline-block;
  vertical-align: middle;
}
.wrapper {
  box-sizing: border-box;
  max-width: none;
  margin: 0 auto;
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  max-width: 1200px;
}
.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
.row.reverse {
  flex-direction: row-reverse;
}
.col.reverse {
  flex-direction: column-reverse;
}
.col {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: auto;
}
.col-1 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 8.333333333333334%;
  max-width: 8.333333333333334%;
}
.col-2 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 16.666666666666668%;
  max-width: 16.666666666666668%;
}
.col-3 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 25%;
  max-width: 25%;
}
.col-4 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 33.333333333333336%;
  max-width: 33.333333333333336%;
}
.col-5 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 41.66666666666667%;
  max-width: 41.66666666666667%;
}
.col-6 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 50%;
  max-width: 50%;
}
.col-7 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 58.333333333333336%;
  max-width: 58.333333333333336%;
}
.col-8 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 66.66666666666667%;
  max-width: 66.66666666666667%;
}
.col-9 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 75%;
  max-width: 75%;
}
.col-10 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 83.33333333333334%;
  max-width: 83.33333333333334%;
}
.col-11 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 91.66666666666667%;
  max-width: 91.66666666666667%;
}
.col-12 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 100%;
  max-width: 100%;
}
.col-offset-0 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 0;
}
.col-offset-1 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 8.333333333333334%;
}
.col-offset-2 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 16.666666666666668%;
}
.col-offset-3 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 25%;
}
.col-offset-4 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 33.333333333333336%;
}
.col-offset-5 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 41.66666666666667%;
}
.col-offset-6 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 50%;
}
.col-offset-7 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 58.333333333333336%;
}
.col-offset-8 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 66.66666666666667%;
}
.col-offset-9 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 75%;
}
.col-offset-10 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 83.33333333333334%;
}
.col-offset-11 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 91.66666666666667%;
}
.col-offset-12 {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 100%;
}
.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
.col-start {
  justify-content: flex-start;
  text-align: left;
}
.col-center {
  justify-content: center;
  text-align: center;
}
.col-end {
  justify-content: flex-end;
  text-align: right;
}
.col-top {
  align-items: flex-start;
}
.col-middle {
  align-items: center;
}
.col-bottom {
  align-items: flex-end;
}
.col-around {
  justify-content: space-around;
}
.col-between {
  justify-content: space-between;
}
.col-first {
  order: -1;
}
.col-last {
  order: 1;
}
.col-auto {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: auto;
  width: auto;
  max-width: none;
}
@media only screen and (min-width: 44em) {
  .container {
    width: 42rem;
  }
  .col-sm {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
  }
  .col-sm-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.333333333333334%;
    max-width: 8.333333333333334%;
  }
  .col-sm-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.666666666666668%;
    max-width: 16.666666666666668%;
  }
  .col-sm-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .col-sm-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .col-sm-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .col-sm-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .col-sm-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .col-sm-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66666666666667%;
    max-width: 91.66666666666667%;
  }
  .col-sm-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-sm-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0;
  }
  .col-sm-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.333333333333334%;
  }
  .col-sm-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.666666666666668%;
  }
  .col-sm-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.333333333333336%;
  }
  .col-sm-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66666666666667%;
  }
  .col-sm-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.333333333333336%;
  }
  .col-sm-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66666666666667%;
  }
  .col-sm-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333333333334%;
  }
  .col-sm-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66666666666667%;
  }
  .col-sm-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }
  .col-sm-auto {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
    width: auto;
    max-width: none;
  }
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-start-sm {
    justify-content: flex-start;
    text-align: left;
  }
  .col-center-sm {
    justify-content: center;
    text-align: center;
  }
  .col-end-sm {
    justify-content: flex-end;
    text-align: right;
  }
  .col-top-sm {
    align-items: flex-start;
  }
  .col-middle-sm {
    align-items: center;
  }
  .col-bottom-sm {
    align-items: flex-end;
  }
  .col-around-sm {
    justify-content: space-around;
  }
  .col-between-sm {
    justify-content: space-between;
  }
  .col-first-sm {
    order: -1;
  }
  .col-last-sm {
    order: 1;
  }
}
@media only screen and (min-width: 56em) {
  .container {
    width: 54rem;
  }
  .col-md {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
  }
  .col-md-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.333333333333334%;
    max-width: 8.333333333333334%;
  }
  .col-md-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.666666666666668%;
    max-width: 16.666666666666668%;
  }
  .col-md-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .col-md-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .col-md-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .col-md-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .col-md-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .col-md-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66666666666667%;
    max-width: 91.66666666666667%;
  }
  .col-md-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-md-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0;
  }
  .col-md-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.333333333333334%;
  }
  .col-md-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.666666666666668%;
  }
  .col-md-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .col-md-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.333333333333336%;
  }
  .col-md-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66666666666667%;
  }
  .col-md-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .col-md-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.333333333333336%;
  }
  .col-md-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66666666666667%;
  }
  .col-md-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .col-md-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333333333334%;
  }
  .col-md-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66666666666667%;
  }
  .col-md-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }
  .col-md-auto {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
    width: auto;
    max-width: none;
  }
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-start-md {
    justify-content: flex-start;
    text-align: left;
  }
  .col-center-md {
    justify-content: center;
    text-align: center;
  }
  .col-end-md {
    justify-content: flex-end;
    text-align: right;
  }
  .col-top-md {
    align-items: flex-start;
  }
  .col-middle-md {
    align-items: center;
  }
  .col-bottom-md {
    align-items: flex-end;
  }
  .col-around-md {
    justify-content: space-around;
  }
  .col-between-md {
    justify-content: space-between;
  }
  .col-first-md {
    order: -1;
  }
  .col-last-md {
    order: 1;
  }
}
@media only screen and (min-width: 70em) {
  .container {
    width: 68rem;
  }
  .col-lg {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
  }
  .col-lg-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.333333333333334%;
    max-width: 8.333333333333334%;
  }
  .col-lg-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.666666666666668%;
    max-width: 16.666666666666668%;
  }
  .col-lg-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .col-lg-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .col-lg-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .col-lg-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .col-lg-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .col-lg-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66666666666667%;
    max-width: 91.66666666666667%;
  }
  .col-lg-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-lg-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0;
  }
  .col-lg-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.333333333333334%;
  }
  .col-lg-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.666666666666668%;
  }
  .col-lg-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.333333333333336%;
  }
  .col-lg-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66666666666667%;
  }
  .col-lg-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.333333333333336%;
  }
  .col-lg-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66666666666667%;
  }
  .col-lg-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333333333334%;
  }
  .col-lg-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66666666666667%;
  }
  .col-lg-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }
  .col-lg-auto {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
    width: auto;
    max-width: none;
  }
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-start-lg {
    justify-content: flex-start;
    text-align: left;
  }
  .col-center-lg {
    justify-content: center;
    text-align: center;
  }
  .col-end-lg {
    justify-content: flex-end;
    text-align: right;
  }
  .col-top-lg {
    align-items: flex-start;
  }
  .col-middle-lg {
    align-items: center;
  }
  .col-bottom-lg {
    align-items: flex-end;
  }
  .col-around-lg {
    justify-content: space-around;
  }
  .col-between-lg {
    justify-content: space-between;
  }
  .col-first-lg {
    order: -1;
  }
  .col-last-lg {
    order: 1;
  }
}
@media only screen and (min-width: 83em) {
  .container {
    width: 81rem;
  }
  .col-xl {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
  }
  .col-xl-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.333333333333334%;
    max-width: 8.333333333333334%;
  }
  .col-xl-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.666666666666668%;
    max-width: 16.666666666666668%;
  }
  .col-xl-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .col-xl-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .col-xl-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .col-xl-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .col-xl-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .col-xl-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66666666666667%;
    max-width: 91.66666666666667%;
  }
  .col-xl-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-xl-offset-0 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0;
  }
  .col-xl-offset-1 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.333333333333334%;
  }
  .col-xl-offset-2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.666666666666668%;
  }
  .col-xl-offset-3 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%;
  }
  .col-xl-offset-4 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.333333333333336%;
  }
  .col-xl-offset-5 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66666666666667%;
  }
  .col-xl-offset-6 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%;
  }
  .col-xl-offset-7 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.333333333333336%;
  }
  .col-xl-offset-8 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66666666666667%;
  }
  .col-xl-offset-9 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%;
  }
  .col-xl-offset-10 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333333333334%;
  }
  .col-xl-offset-11 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66666666666667%;
  }
  .col-xl-offset-12 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%;
  }
  .col-xl-auto {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto;
    width: auto;
    max-width: none;
  }
  .col-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-start-xl {
    justify-content: flex-start;
    text-align: left;
  }
  .col-center-xl {
    justify-content: center;
    text-align: center;
  }
  .col-end-xl {
    justify-content: flex-end;
    text-align: right;
  }
  .col-top-xl {
    align-items: flex-start;
  }
  .col-middle-xl {
    align-items: center;
  }
  .col-bottom-xl {
    align-items: flex-end;
  }
  .col-around-xl {
    justify-content: space-around;
  }
  .col-between-xl {
    justify-content: space-between;
  }
  .col-first-xl {
    order: -1;
  }
  .col-last-xl {
    order: 1;
  }
}
* {
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 !important;
  -webkit-font-smoothing: antialiased;
}
html,
body {
  height: 100%;
}
body {
  line-height: 1;
  background-color: #fff;
  background-image: none;
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
  color: #000;
  text-align: left;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  overflow-x: hidden !important;
}
* {
  box-sizing: border-box;
}
label,
input[type="button"],
input[type="submit"],
button {
  cursor: pointer;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
.btn {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  border-radius: $border-radius;
  font-weight: normal;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  text-align: center;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  border-radius: 2px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 10px;
}
.btn--primary {
  color: #fff;
  background-color: #2f80ed;
  border: 0;
}
.btn--primary:visited {
  color: #fff;
}
.btn--primary:hover {
  color: #fff;
  background-color: #1264d1;
}
.btn--primary:focus,
.btn--primary.focus,
.btn--primary:active,
.btn--primary.active {
  color: #fff;
  background-color: #ed217c;
}
.btn--primary.disabled,
.btn--primary:disabled {
  background-color: #ed217c;
}
.btn--block {
  display: block;
}
.container {
  padding: 0 0.5rem;
  max-width: $screenLarge;
  margin: 0 auto;
  position: relative;
}
.container:before,
.container:after {
  content: " ";
  display: table;
}
.container:after {
  clear: both;
}
.embed {
  display: block;
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.embed iframe,
.embed embed,
.embed object,
.embed video {
  border: 0;
  bottom: 0;
  height: 100% !important;
  left: 0;
  position: absolute;
  top: 0;
  width: 100% !important;
}
.embed-16-9 {
  padding-bottom: 56.25% /* 16:9 */;
}
.embed-4-3 {
  padding-bottom: 75% /* 4:3 */;
}
.form-group {
  display: block;
}
.form-control-container {
  position: relative;
  margin-bottom: 10px;
}
.form-control {
  font-family: 'Open Sans', sans-serif;
  outline: 0;
  color: #fff;
  border-radius: 2px;
  padding: 5px 15px;
  height: 56px;
  line-height: 35px;
  background-color: rgba(48,48,57,0.8);
  width: 100%;
  border: 0;
  border-bottom: 1px solid #979797;
  -moz-appearance: textfield;
}
.form-control::-webkit-inner-spin-button,
.form-control::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-control::-webkit-contacts-auto-fill-button,
.form-control::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
.form-control:-webkit-autofill {
/* Change the color to your own background color */
  -webkit-box-shadow: 0 0 0 50px #fff inset;
  -webkit-text-fill-color: #000;
}
.form-control:focus {
  outline: 0;
  border-bottom: 1px solid #2f80ed;
}
.form-control:disabled {
  background-color: #ddd;
}
textarea {
  font-family: 'Open Sans', sans-serif;
  outline: 0;
  padding: 5px 10px;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #ddd;
  resize: none;
  -ms-overflow-style: none;
}
textarea::-webkit-scrollbar {
  display: none;
}
textarea:focus {
  outline: 0;
  border: 1px solid #2f80ed;
}
textarea:disabled {
  background-color: #ddd;
}
select {
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 2px;
  height: 35px;
  padding: 5px;
  line-height: 25px;
}
select[multiple] {
  height: auto;
  min-height: 35px;
}
input[type="radio"],
input[type="checkbox"] {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
}
input[type="radio"] + label,
input[type="checkbox"] + label {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}
input[type="radio"] select,
input[type="checkbox"] select,
input[type="radio"] .select,
input[type="checkbox"] .select {
  border: 1px solid #e43b2d;
}
ul.errorlist {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #e43b2d;
}
ul.errorlist li {
  padding: 0;
  margin: 0;
}
@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot");
  src: url("../fonts/icons.eot?#iefix") format('eot'), url("../fonts/icons.woff2") format('woff2'), url("../fonts/icons.woff") format('woff'), url("../fonts/icons.ttf") format('truetype'), url("../fonts/icons.svg#icons") format('svg');
  font-weight: normal;
  font-style: normal;
}
.icon {
/* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
/* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-2x {
  font-size: 2rem;
}
.icon-3x {
  font-size: 3em;
}
.icon-4x {
  font-size: 4em;
}
.icon-5x {
  font-size: 5em;
}
.icon-android:before {
  content: "\EA01";
}
.icon-apple:before {
  content: "\EA02";
}
.icon-chevron-left:before {
  content: "\EA03";
}
.icon-chevron-right:before {
  content: "\EA04";
}
.icon-comments:before {
  content: "\EA05";
}
.icon-facebook:before {
  content: "\EA06";
}
.icon-feed-list:before {
  content: "\EA07";
}
.icon-feed:before {
  content: "\EA08";
}
.icon-inbox:before {
  content: "\EA09";
}
.icon-instagram:before {
  content: "\EA0A";
}
.icon-like:before {
  content: "\EA0B";
}
.icon-profile:before {
  content: "\EA0C";
}
.icon-radar:before {
  content: "\EA0D";
}
.icon-twitter:before {
  content: "\EA0E";
}
.icon-user:before {
  content: "\EA0F";
}
.img--responsive {
  max-width: 100%;
}
.img--center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
a {
  color: #2f80ed;
  text-decoration: underline;
  transition: color 0.3s;
}
a:visited {
  color: #2f80ed;
  text-decoration: underline;
}
a:hover {
  color: #2f80ed;
  text-decoration: none;
}
a:focus,
a:active {
  color: #2f80ed;
  text-decoration: none;
  outline: none;
}
a.disabled {
  pointer-events: none;
}
a.dotted {
  border-bottom: 1px dashed #2f80ed;
  text-decoration: none;
}
a.dotted:visited {
  border-bottom: 1px dashed #2f80ed;
  text-decoration: none;
}
a.dotted:hover {
  border-bottom: 1px dashed #2f80ed;
  text-decoration: none;
}
a.dotted:focus,
a.dotted:active {
  border-bottom: 1px dashed #2f80ed;
  text-decoration: none;
}
input[type="text"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #979797;
}
input[type="text"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="url"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="date"]::-moz-placeholder,
textarea::-moz-placeholder {
  color: #979797;
  opacity: 1;
}
input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #979797;
}
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="number"]::placeholder,
input[type="url"]::placeholder,
input[type="tel"]::placeholder,
input[type="date"]::placeholder,
textarea::placeholder {
  color: #979797;
}
input[type="text"]:placeholder,
input[type="email"]:placeholder,
input[type="password"]:placeholder,
input[type="number"]:placeholder,
input[type="url"]:placeholder,
input[type="tel"]:placeholder,
input[type="date"]:placeholder,
textarea:placeholder {
  color: #979797;
}
input[type="text"]:focus::-webkit-input-placeholder,
input[type="email"]:focus::-webkit-input-placeholder,
input[type="password"]:focus::-webkit-input-placeholder,
input[type="number"]:focus::-webkit-input-placeholder,
input[type="url"]:focus::-webkit-input-placeholder,
input[type="tel"]:focus::-webkit-input-placeholder,
input[type="date"]:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
input[type="text"]:focus::-moz-placeholder,
input[type="email"]:focus::-moz-placeholder,
input[type="password"]:focus::-moz-placeholder,
input[type="number"]:focus::-moz-placeholder,
input[type="url"]:focus::-moz-placeholder,
input[type="tel"]:focus::-moz-placeholder,
input[type="date"]:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent;
}
input[type="text"]:focus:-ms-input-placeholder,
input[type="email"]:focus:-ms-input-placeholder,
input[type="password"]:focus:-ms-input-placeholder,
input[type="number"]:focus:-ms-input-placeholder,
input[type="url"]:focus:-ms-input-placeholder,
input[type="tel"]:focus:-ms-input-placeholder,
input[type="date"]:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent;
}
input[type="text"]:focus::placeholder,
input[type="email"]:focus::placeholder,
input[type="password"]:focus::placeholder,
input[type="number"]:focus::placeholder,
input[type="url"]:focus::placeholder,
input[type="tel"]:focus::placeholder,
input[type="date"]:focus::placeholder,
textarea:focus::placeholder {
  color: transparent;
}
input[type="text"]:focus:placeholder,
input[type="email"]:focus:placeholder,
input[type="password"]:focus:placeholder,
input[type="number"]:focus:placeholder,
input[type="url"]:focus:placeholder,
input[type="tel"]:focus:placeholder,
input[type="date"]:focus:placeholder,
textarea:focus:placeholder {
  color: transparent;
}
.popup {
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: #fff;
}
.popup--small {
  max-width: 400px;
}
.popup--middle {
  max-width: $screenTablet;
}
.popup--large {
  max-width: $screenLarge;
}
.popup-content {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}
.popup-content:before,
.popup-content:after {
  content: " ";
  display: table;
}
.popup-content:after {
  clear: both;
}
.section {
  overflow: hidden;
}
.table {
  width: 100%;
}
.table th {
  font-weight: bold;
}
.table th,
.table td {
  padding: 10px 5px;
  border-bottom: 1px solid #c2d1d9;
}
.table tbody tr:nth-child(odd) > th,
.table tbody tr:nth-child(odd) > td {
  background-color: #fff;
}
.table tbody tr:nth-child(even) > th,
.table tbody tr:nth-child(even) > td {
  background-color: #f9f9f9;
}
.table--responsive {
  display: block;
}
.table--responsive tr {
  display: block;
}
.table--responsive th,
.table--responsive td {
  display: block;
  width: auto;
}
.table--responsive th:before,
.table--responsive td:before {
  content: attr(title);
  font-weight: bold;
  display: block;
}
.table--responsive thead {
  display: block;
}
.table--responsive tbody {
  display: block;
}
.table--mb-hidden-header thead {
  display: none;
}
@media screen and (min-width: $screenTabletPortrait) {
  .table--responsive th:before,
  .table--responsive td:before {
    display: inline-block;
    vertical-align: top;
  }
}
@media screen and (min-width: $screenTablet) {
  .table th,
  .table td {
    vertical-align: top;
  }
  .table--responsive {
    display: table;
  }
  .table--responsive tr {
    display: table-row;
  }
  .table--responsive th,
  .table--responsive td {
    display: table-cell;
  }
  .table--responsive th:before,
  .table--responsive td:before {
    display: none;
  }
  .table--responsive thead {
    display: table-header-group;
  }
  .table--responsive tbody {
    display: table-row-group;
  }
  .table--mb-hidden-header thead {
    display: table-header-group;
  }
  .table--layout-fixed {
    table-layout: fixed;
  }
  .table--no-border tbody tr > th,
  .table--no-border tbody tr > td {
    border-bottom: 0;
  }
}
.vertical-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 auto;
}
.loading-spinner {
  position: relative;
  color: transparent !important;
  transition: none !important;
}
.loading-spinner:after {
  content: "";
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-name: rotate-forever;
  animation-timing-function: linear;
  height: 16px;
  width: 16px;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -10px;
}
.spinner-body {
  overflow: hidden;
}
#spinner-wrapper {
  z-index: 999999;
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.9);
}
#spinner-wrapper.loading {
  display: block;
}
.center-spinner {
  display: none;
  position: absolute;
  min-height: 96px;
  height: 100%;
  width: 100%;
  z-index: 999999;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.9);
}
.center-spinner.loading {
  display: block;
}
.line-spinner {
  display: none;
  position: relative;
  height: 96px;
  padding: 15px;
}
.line-spinner.loading {
  display: block;
}
.loader {
  z-index: 2800;
  top: 50% !important;
  left: 50% !important;
  margin-top: -48px !important;
  margin-left: -48px !important;
  font-size: 14px;
  position: absolute;
  text-indent: -9999em;
  border: 5px solid transparent;
  border-bottom-color: #000;
  border-top-color: #000;
  animation: rotate 1.5s linear 0s infinite;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
@-moz-keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    position: absolute;
    left: -9999px;
  }
}
@-webkit-keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    position: absolute;
    left: -9999px;
  }
}
@-o-keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    position: absolute;
    left: -9999px;
  }
}
@keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    position: absolute;
    left: -9999px;
  }
}
@-moz-keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.panel {
  height: 100%;
  position: relative;
  left: 0;
  margin-right: 0;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
}
.main {
  flex: 1 0 auto;
  width: 100%;
  overflow: visible;
  position: relative;
  background-color: #fff;
}
.main:after {
  content: "\00a0";
  display: block;
  height: 0px;
  visibility: hidden;
}
input[type="checkbox"].nav-trigger {
  position: fixed;
  left: -999px;
}
input[type="checkbox"].nav-trigger + label {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 21;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.panel,
header {
  transition: left 0.2s, margin-right 0.2s;
}
header {
  padding-right: 50px;
}
@media screen and (min-width: 56em) {
  header {
    padding-right: 0;
  }
}
input[type="checkbox"].nav-trigger:checked + label {
  right: 0;
}
input[type="checkbox"].nav-trigger:checked ~ .panel {
  left: 256px;
}
input[type="checkbox"].nav-trigger:checked ~ .panel header {
  left: 256px;
}
input[type="checkbox"].nav-trigger:checked ~ nav.menu {
  left: 0;
}
@media screen and (min-width: 56em) {
  input[type="checkbox"].nav-trigger:checked + label,
  input[type="checkbox"].nav-trigger + label {
    z-index: -9999;
  }
  input[type="checkbox"].nav-trigger:checked ~ .panel,
  input[type="checkbox"].nav-trigger ~ .panel {
    left: 0;
    margin-right: 0;
  }
  input[type="checkbox"].nav-trigger:checked ~ .panel header,
  input[type="checkbox"].nav-trigger ~ .panel header {
    left: 0;
    padding-right: 0;
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  flex: none;
  padding-top: 5px;
  padding-bottom: 5px;
}
.header-container {
  position: relative;
  z-index: 3;
}
@media screen and (min-width: 56em) {
  .header-container {
    display: flex;
  }
}
.btn-burger {
  padding: 12px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter, left;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.btn-burger:hover {
  opacity: 0.7;
}
@media screen and (min-width: 56em) {
  .btn-burger {
    display: none;
  }
}
input[type="checkbox"].nav-trigger:checked + .btn-burger:hover {
  opacity: 0.7;
}
input[type="checkbox"].nav-trigger:checked + .btn-burger .btn-burger-inner,
input[type="checkbox"].nav-trigger:checked + .btn-burger .btn-burger-inner::before,
input[type="checkbox"].nav-trigger:checked + .btn-burger .btn-burger-inner::after {
  background-color: #fff;
}
input[type="checkbox"].nav-trigger:checked + .btn-burger .btn-burger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
input[type="checkbox"].nav-trigger:checked + .btn-burger .btn-burger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
input[type="checkbox"].nav-trigger:checked + .btn-burger .btn-burger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.btn-burger-box {
  width: 35px;
  height: 35px;
  display: inline-block;
  position: relative;
}
.btn-burger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
.btn-burger-inner::before {
  top: -10px;
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.btn-burger-inner::after {
  bottom: -10px;
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.btn-burger-inner,
.btn-burger-inner::before,
.btn-burger-inner::after {
  width: 35px;
  height: 4px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.btn-burger-inner::before,
.btn-burger-inner::after {
  content: "";
  display: block;
}
.menu-list {
  list-style: none;
}
.menu-list__item {
  display: block;
  padding: 0;
  margin: 0;
}
.menu-list__link--active,
.menu-list__link:hover {
  color: inherit;
}
.menu-list__link--active {
  text-decoration: none;
  cursor: auto;
}
.menu {
  width: 256px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: -256px;
  z-index: 0;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: #212121;
  padding: 1rem/2;
  transition: left all 0.2s;
}
.app-links {
  list-style: none;
  margin: 0;
  padding: 0;
}
.app-links__link:focus,
.app-links__link:hover {
  opacity: 0.7;
}
.footer {
  flex: none;
}
.menu {
  padding: 30px 15px 15px 15px;
}
.menu-list-side {
  margin-bottom: 30px;
}
.menu-list-side__item {
  padding: 10px 0;
  text-transform: uppercase;
}
a.menu-list-side__link {
  color: #fff;
  text-decoration: none;
}
a.menu-list-side__link:hover {
  text-decoration: none;
  color: #2f80ed;
}
a.menu-list-side__link:active,
a.menu-list-side__link:focus,
a.menu-list-side__link.active {
  color: #ed217c;
  text-decoration: none;
  cursor: auto;
}
a.menu-list-side__link:active:hover,
a.menu-list-side__link:focus:hover,
a.menu-list-side__link.active:hover {
  text-decoration: none;
  color: #2f80ed;
}
.logo-wrap {
  width: 239px;
  transition: width 0.2s;
}
@media screen and (min-width: 56em) {
  .logo-wrap {
    margin-right: 20px;
  }
}
@media screen and (min-width: 70em) {
  .logo-wrap {
    margin-right: 50px;
  }
}
.logo {
  width: 100%;
  transition: all 0.2s;
}
.app-links-header {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s;
  display: flex;
  align-self: center;
}
@media screen and (min-width: 56em) {
  .app-links-header__item {
    margin: 0 15px 0 0;
    padding: 0;
  }
  .app-links-header__item:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 56em) {
  .app-links-header__img {
    height: 35px;
  }
}
@media screen and (min-width: 70em) {
  .app-links-header__img {
    height: 40px;
  }
}
.menu-list-header {
  display: none;
  position: relative;
}
@media screen and (min-width: 56em) {
  .menu-list-header {
    text-transform: uppercase;
    align-self: center;
    display: flex;
    flex: 1 1 auto;
    list-style: none;
    margin: 0;
    font-size: 12px;
  }
}
@media screen and (min-width: 56em) {
  .menu-list-header__item {
    margin-right: 20px;
  }
  .menu-list-header__item:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 70em) {
  .menu-list-header__item {
    margin-right: 50px;
  }
  .menu-list-header__item:last-child {
    margin-right: 0;
  }
}
a.menu-list-header__link {
  color: rgba(255,255,255,0.8);
  text-decoration: none;
}
a.menu-list-header__link:hover {
  text-decoration: none;
  color: #2f80ed;
}
@media screen and (min-width: 56em) {
  a.menu-list-header__link:active,
  a.menu-list-header__link:focus,
  a.menu-list-header__link.active {
    color: #ed217c;
    text-decoration: none;
    cursor: auto;
  }
  a.menu-list-header__link:active:hover,
  a.menu-list-header__link:focus:hover,
  a.menu-list-header__link.active:hover {
    text-decoration: none;
    color: #2f80ed;
  }
}
.header {
  height: 80px;
  background-color: transparent;
  transition: all 0.2s;
  z-index: 20;
}
.header--fixed {
  height: 60px;
  background-color: #212121;
}
.header--fixed .logo-wrap {
  width: 170px;
}
@media screen and (min-width: 56em) {
  .header--fixed .app-links {
    visibility: visible;
    opacity: 1;
  }
}
.footer {
  background-color: #212121;
  color: rgba(255,255,255,0.8);
  font-size: 14px;
  line-height: 1.5;
}
.footer__btns {
  margin-top: 15px;
}
.footer__top {
  border-bottom: 1px solid rgba(194,209,217,0.2);
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
}
@media screen and (min-width: 56em) {
  .footer__top {
    text-align: left;
  }
}
.footer__bottom {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}
@media screen and (min-width: 56em) {
  .footer__bottom {
    text-align: left;
  }
}
.footer__logo {
  margin-bottom: 10px;
}
.footer__h3 {
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 15px;
}
@media screen and (min-width: 56em) {
  .footer__download-cont {
    padding-left: 30px;
  }
}
.footer__item {
  margin-bottom: 20px;
}
.footer__item--product {
  margin-bottom: 10px;
}
@media screen and (min-width: 56em) {
  .footer__item {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 56em) {
  .menu-list-footer-cont {
    padding-left: 15px;
    padding-right: 15px;
    border-left: 1px solid rgba(194,209,217,0.2);
    border-right: 1px solid rgba(194,209,217,0.2);
  }
}
@media screen and (min-width: 44em) {
  .menu-list-footer {
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 56em) {
  .menu-list-footer {
    display: block;
  }
}
.menu-list-footer__item {
  margin-bottom: 10px;
}
@media screen and (min-width: 44em) {
  .menu-list-footer__item {
    margin-right: 15px;
  }
  .menu-list-footer__item:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 56em) {
  .menu-list-footer__item {
    margin-right: 0;
  }
}
a.menu-list-footer__link {
  text-decoration: none;
  color: rgba(255,255,255,0.6);
}
a.menu-list-footer__link:focus {
  color: #ed217c;
}
a.menu-list-footer__link:hover {
  color: #ed217c;
  text-decoration: underline;
}
.app-links-footer__item {
  margin-bottom: 15px;
}
.app-links-footer__img {
  max-width: 100%;
}
.footer-links {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-links__item {
  display: inline-block;
  margin-right: 40px;
}
.footer-links__item:last-child {
  margin-right: 0;
}
a.footer-links__link {
  text-decoration: none;
  color: rgba(255,255,255,0.8);
}
a.footer-links__link:focus {
  color: #ed217c;
}
a.footer-links__link:hover {
  color: #ed217c;
  text-decoration: underline;
}
.visibility {
  opacity: 0;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
@media (print), (prefers-reduced-motion) {
  .animated {
    animation: unset !important;
    transition: none !important;
  }
}
.animated.infinite {
  animation-iteration-count: infinite;
}
.animated.delay-2s {
  animation-delay: 2s;
}
.animated.delay-02s {
  animation-delay: 0.2s;
}
.animated.delay-04s {
  animation-delay: 0.4s;
}
.fade-in-up {
  animation-name: fadeInUp;
}
.slide-in-up {
  animation-name: slideInUp;
}
.slide-in-left {
  animation-name: slideInLeft;
}
.slide-in-right {
  animation-name: slideInRight;
}
.fade-in-left {
  animation-name: fadeInLeft;
}
.fade-in-right {
  animation-name: fadeInRight;
}
@-moz-keyframes slideInLeft {
  from {
    transform: translate3d(-1000%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInLeft {
  from {
    transform: translate3d(-1000%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInLeft {
  from {
    transform: translate3d(-1000%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInLeft {
  from {
    transform: translate3d(-1000%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slideInRight {
  from {
    transform: translate3d(500%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInRight {
  from {
    transform: translate3d(500%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInRight {
  from {
    transform: translate3d(500%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInRight {
  from {
    transform: translate3d(500%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes slideInUp {
  from {
    transform: translate3d(0, 400%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slideInUp {
  from {
    transform: translate3d(0, 400%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes slideInUp {
  from {
    transform: translate3d(0, 400%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInUp {
  from {
    transform: translate3d(0, 400%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.app-links-about__item {
  display: inline-block;
  margin-right: 15px;
}
.app-links-about__img {
  height: 55px;
}
.slider__controls-wrap {
  position: absolute;
  z-index: 3;
  bottom: 13px;
  left: 0;
  right: 0;
  text-align: center;
}
.slider__controls {
  display: inline-block;
  background: #212121;
  border-radius: 10px;
  height: 20px;
  line-height: 22px;
  padding: 0 5px;
}
.slider__controls-item {
  border: 2px solid #979797;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  font-size: 0;
  padding: 0;
  background-color: transparent;
  margin: 0 5px 5px;
  display: inline-block;
  vertical-align: middle;
}
.slider__controls-item:focus,
.slider__controls-item:hover {
  background-color: #fff;
  outline: 0;
}
.slider__controls-item.glide__bullet--active {
  background-color: #ed217c;
  border: 1px solid #212121;
}
.about {
  padding-top: 80px;
  background-color: #220e2a;
  color: #fff;
  min-height: 85vh;
  display: flex;
  background-image: url("../images/discover-bg.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.about__container {
  display: flex;
  padding-top: 20px;
}
@media screen and (min-width: 44em) {
  .about__container {
    align-self: flex-end;
  }
}
.about__desc {
  align-self: center;
  text-align: center;
}
@media screen and (min-width: 44em) {
  .about__desc {
    text-align: left;
  }
}
.about__img-cont {
  display: none;
}
@media screen and (min-width: 44em) {
  .about__img-cont {
    display: block;
    align-self: flex-end;
    text-align: center;
  }
}
.about__img-wrap {
  position: relative;
  float: left;
}
.about__img {
  display: block;
}
.about__logo-wrap {
  position: relative;
  z-index: 2;
  align-self: center;
  margin: 0 auto;
}
.about__logo-cont {
  display: none;
}
@media screen and (min-width: 56em) {
  .about__logo-cont {
    display: flex;
    position: static;
  }
}
.about__subheader {
  color: #2f80ed;
  font-size: 21px;
  line-height: 1.25;
  margin-bottom: 10px;
}
.about__h2 {
  font-size: 40px;
  line-height: 1.25;
  color: #ed217c;
  margin-bottom: 40px;
}
@media screen and (min-width: 56em) {
  .about__h2 {
    font-size: 56px;
    margin-bottom: 60px;
  }
}
.about__slider-wrap {
  max-width: 284px;
  width: 100%;
  margin: 0 auto;
}
.about__slider-wrap .slider__controls-wrap {
  bottom: 30px;
}
.about__slider-wrap .glide__slide {
  max-height: 546px;
  overflow: hidden;
}
.about__slider-wrap .glide__slide img {
  max-width: 100%;
}
.about__slider-bg {
  position: relative;
  width: 326px;
  height: 550px;
  background-image: url("../images/iphone-about.png");
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 16px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 124.8dpi), only screen and (min-resolution: 1.3dppx) {
  .about__slider-bg {
    background-image: url("../images/iphone-about-2x.png");
    background-size: 326px 653px;
  }
}
.about__slider-bg:before {
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  content: '';
  height: 45px;
  background-image: url("../images/iphone-about.png");
  background-repeat: no-repeat;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 124.8dpi), only screen and (min-resolution: 1.3dppx) {
  .about__slider-bg:before {
    background-image: url("../images/iphone-about-2x.png");
    background-size: 326px 653px;
  }
}
.about__slider-bg:before {
  top: 0;
  background-position: top center;
}
.how-it-works {
  background-color: #ed217c;
  color: #fff;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
}
.how-it-works__container {
  display: flex;
  flex-direction: column;
}
.how-it-works__desc {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 40px;
}
.how-it-works__strong {
  font-weight: inherit;
}
.how-it-works__h2 {
  font-size: 40px;
  line-height: 1.25;
  margin-bottom: 20px;
  text-align: center;
}
@media screen and (min-width: 56em) {
  .how-it-works__h2 {
    text-align: left;
    font-size: 50px;
  }
}
.how-it-works__h3 {
  font-size: 21px;
  line-height: 1.5;
  margin-bottom: 25px;
}
.how-it-works__list {
  counter-reset: my-awesome-counter;
  list-style: none;
  padding-left: 40px;
}
.how-it-works__list-item {
  margin-bottom: 35px;
  font-size: 14px;
  line-height: 1.5;
  counter-increment: my-awesome-counter;
  position: relative;
  padding-left: 55px;
}
.how-it-works__list-item:before {
  content: counter(my-awesome-counter);
  color: #fff;
  font-size: 16px;
  position: absolute;
  left: 0;
  background: #2f80ed;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  text-align: center;
  line-height: 38px;
}
.how-it-works__desc-cont {
  align-self: center;
}
.how-it-works__img-cont {
  display: none;
}
@media screen and (min-width: 44em) {
  .how-it-works__img-cont {
    display: block;
    align-self: flex-end;
  }
}
.how-it-works__img-wrap {
  position: relative;
  float: left;
}
.how-it-works__img {
  display: block;
}
.how-it-works__slider-wrap {
  max-width: 390px;
  width: 100%;
  margin: 0 auto;
}
.how-it-works__slider-wrap .slider__controls-wrap {
  bottom: 40px;
}
.how-it-works__slider-wrap .glide__track {
  background: #302f31;
}
.how-it-works__slider-wrap .glide__slide {
  max-height: 863px;
  overflow: hidden;
  text-align: center;
}
.how-it-works__slider-wrap .glide__slide img {
  max-width: 100%;
}
.how-it-works__slider-bg {
  position: relative;
  width: 450px;
  height: 845px;
  background-image: url("../images/iphone-how-it-works.png");
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 16px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 124.8dpi), only screen and (min-resolution: 1.3dppx) {
  .how-it-works__slider-bg {
    background-size: 450px 898px;
  }
}
.how-it-works__slider-bg:before {
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 3;
  content: '';
  height: 65px;
  background-image: url("../images/iphone-how-it-works.png");
  background-repeat: no-repeat;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 124.8dpi), only screen and (min-resolution: 1.3dppx) {
  .how-it-works__slider-bg:before {
    background-size: 450px 898px;
  }
}
.how-it-works__slider-bg:before {
  top: 0;
  background-position: top center;
}
.app-links-features {
  text-align: center;
}
.app-links-features__item {
  display: inline-block;
  margin: 0 10px 10px;
}
.features {
  background-color: #212121;
  color: #fff;
  padding: 80px 0;
}
.features__track .glide__slide img {
  max-width: 100%;
}
.features__container {
  margin-bottom: 40px;
}
@media screen and (min-width: 56em) {
  .features__container {
    justify-content: flex-end;
  }
}
.features__h2 {
  font-size: 40px;
  line-height: 1.25;
  text-align: center;
  margin-bottom: 40px;
}
@media screen and (min-width: 56em) {
  .features__h2 {
    font-size: 50px;
    margin-bottom: 80px;
  }
}
.features__h3 {
  font-size: 28px;
  line-height: 1.5;
  margin-bottom: 25px;
  text-align: center;
}
.features__desc {
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 40px;
  max-width: 790px;
  margin: 0 auto 20px;
}
.features__strong {
  font-weight: inherit;
}
.features__img-cont {
  display: none;
}
@media screen and (min-width: 56em) {
  .features__img-cont {
    display: block;
    order: 1;
    position: relative;
  }
}
@media screen and (min-width: 70em) {
  .features__img-cont {
    order: 2;
  }
}
.features__slider-wrap {
  max-width: 310px;
  width: 100%;
  margin: 0 auto;
}
.features__slider-bg {
  position: relative;
  width: 356px;
  height: 713px;
  background-image: url("../images/iphone.png");
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 20px;
}
@media screen and (min-width: 56em) {
  .features__slider-bg {
    position: absolute;
    right: 0;
  }
}
@media screen and (min-width: 70em) {
  .features__slider-bg {
    position: relative;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 124.8dpi), only screen and (min-resolution: 1.3dppx) {
  .features__slider-bg {
    background-image: url("../images/iphone-2x.png");
    background-size: 356px 713px;
  }
}
.features__slider-bg:before,
.features__slider-bg:after {
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  content: '';
  height: 50px;
  background-image: url("../images/iphone.png");
  background-repeat: no-repeat;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 124.8dpi), only screen and (min-resolution: 1.3dppx) {
  .features__slider-bg:before,
  .features__slider-bg:after {
    background-image: url("../images/iphone-2x.png");
    background-size: 356px 713px;
  }
}
.features__slider-bg:before {
  top: 0;
  background-position: top center;
}
.features__slider-bg:after {
  bottom: 0;
  background-position: bottom center;
}
.features-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  position: relative;
  z-index: 10;
}
@media screen and (min-width: 56em) {
  .features-list--left {
    order: 2;
  }
  .features-list--left .features-list__item {
    padding-left: 20px;
  }
}
@media screen and (min-width: 70em) {
  .features-list--left {
    order: 1;
  }
  .features-list--left .features-list__item {
    padding-left: 0;
    padding-right: 20px;
  }
  .features-list--left .features-list__btn {
    text-align: right;
  }
  .features-list--left .features-list__desc-wrap {
    order: 1;
  }
  .features-list--left .features-list__icon-wrap {
    order: 2;
  }
}
@media screen and (min-width: 56em) {
  .features-list--right {
    order: 3;
  }
  .features-list--right .features-list__item {
    padding-left: 20px;
  }
  .features-list--right .features-list__btn:before {
    left: -55px;
    right: auto;
  }
  .features-list--right .features-list__btn:after {
    left: -60px;
    right: auto;
  }
}
.features-list__item {
  padding: 0;
  margin: 0 0 10px 0;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}
.features-list__btn {
  padding: 0;
  margin: 0;
  border: 0;
  display: block;
  width: 100%;
  transition: background-color 0.2s;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
}
.features-list__btn:focus,
.features-list__btn:hover {
  outline: 0;
  background-color: rgba(47,128,237,0.6);
}
.features-list__btn:focus .features-list__icon-wrap,
.features-list__btn:hover .features-list__icon-wrap,
.features-list__btn:focus .features-list__desc-wrap,
.features-list__btn:hover .features-list__desc-wrap {
  color: #fff;
}
.features-list__btn.active {
  background-color: #2f80ed;
}
@media screen and (min-width: 70em) {
  .features-list__btn.active {
    position: relative;
  }
  .features-list__btn.active:after,
  .features-list__btn.active:before {
    content: '';
    position: absolute;
    display: block;
    background-color: #2f80ed;
  }
  .features-list__btn.active:before {
    width: 55px;
    height: 1px;
    right: -55px;
    top: 55px;
  }
  .features-list__btn.active:after {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    right: -60px;
    top: 48px;
  }
}
.features-list__btn.active .features-list__icon-wrap,
.features-list__btn.active .features-list__desc-wrap {
  color: #fff;
}
.features-list__btn-data {
  padding: 20px 13px;
  display: flex;
}
.features-list__icon-wrap {
  font-size: 33px;
  color: rgba(255,255,255,0.8);
  flex: 0 0 41px;
}
.features-list__desc-wrap {
  color: rgba(255,255,255,0.8);
  min-width: 50%;
}
.features-list__h3 {
  color: #fff;
  font-size: 21px;
  line-height: 1.5;
  margin-bottom: 20px;
}
.features-list__desc {
  line-height: 1.5;
}
.glide {
  position: relative;
  width: 100%;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}
.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: #fff;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255,255,255,0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0,0,0,0.1);
  text-shadow: 0 0.25em 0.5em rgba(0,0,0,0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
}
.glide__arrow:focus {
  outline: none;
}
.glide__arrow:hover {
  border-color: #fff;
}
.glide__arrow--left {
  left: 2em;
}
.glide__arrow--right {
  right: 2em;
}
.glide__arrow--disabled {
  opacity: 0.33;
}
.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
}
.glide__bullet {
  background-color: rgba(255,255,255,0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0,0,0,0.1);
  margin: 0 0.25em;
}
.glide__bullet:focus {
  outline: none;
}
.glide__bullet:hover,
.glide__bullet:focus {
  border: 2px solid #fff;
  background-color: rgba(255,255,255,0.5);
}
.glide__bullet--active {
  background-color: #fff;
}
.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}
[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}
[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
  transition-duration: 0.1s;
}
[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: 0.1s;
}
[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
  transition-duration: 0.15s;
}
[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: 0.15s;
}
[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
  transition-duration: 0.2s;
}
[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: 0.2s;
}
[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
  transition-duration: 0.25s;
}
[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0.25s;
}
[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
  transition-duration: 0.3s;
}
[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: 0.3s;
}
[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
  transition-duration: 0.35s;
}
[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: 0.35s;
}
[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}
[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: 0.4s;
}
[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
  transition-duration: 0.45s;
}
[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: 0.45s;
}
[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
  transition-duration: 0.5s;
}
[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: 0.5s;
}
[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
  transition-duration: 0.55s;
}
[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: 0.55s;
}
[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
  transition-duration: 0.6s;
}
[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: 0.6s;
}
[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
  transition-duration: 0.65s;
}
[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: 0.65s;
}
[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
  transition-duration: 0.7s;
}
[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: 0.7s;
}
[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
  transition-duration: 0.75s;
}
[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: 0.75s;
}
[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s;
}
[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: 0.8s;
}
[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
  transition-duration: 0.85s;
}
[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: 0.85s;
}
[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s;
}
[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: 0.9s;
}
[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
  transition-duration: 0.95s;
}
[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: 0.95s;
}
[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}
[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}
[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}
[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}
[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}
[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}
[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}
[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}
[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}
[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}
[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}
[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}
[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}
[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}
[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}
[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}
[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}
[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}
[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}
[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}
[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}
[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}
[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}
[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}
[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}
[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}
[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}
[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}
[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}
[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}
[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}
[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}
[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}
[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}
[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}
[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}
[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}
[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}
[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}
[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}
[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}
[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}
[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}
[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}
[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}
[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}
[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}
[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}
[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}
[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}
[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}
[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}
[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}
[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}
[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}
[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}
[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}
[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}
[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}
[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}
[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}
[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}
[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}
[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}
[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}
[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}
[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}
[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}
[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}
[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}
[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}
[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}
[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}
[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}
[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}
[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}
[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}
[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}
[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}
[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}
[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}
[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0s;
}
[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}
[data-aos] {
  pointer-events: none;
}
[data-aos].aos-animate {
  pointer-events: auto;
}
[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}
[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}
[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}
[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}
[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}
[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@media screen {
  html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity, transform;
  }
}
html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: none;
}
html:not(.no-js) [data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}
html:not(.no-js) [data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}
html:not(.no-js) [data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}
html:not(.no-js) [data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}
html:not(.no-js) [data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}
html:not(.no-js) [data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}
html:not(.no-js) [data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}
html:not(.no-js) [data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}
html:not(.no-js) [data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}
html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}
html:not(.no-js) [data-aos=zoom-in] {
  transform: scale(0.6);
}
html:not(.no-js) [data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}
html:not(.no-js) [data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}
html:not(.no-js) [data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}
html:not(.no-js) [data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}
html:not(.no-js) [data-aos=zoom-out] {
  transform: scale(1.2);
}
html:not(.no-js) [data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}
html:not(.no-js) [data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}
html:not(.no-js) [data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}
html:not(.no-js) [data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}
html:not(.no-js) [data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
  visibility: hidden;
}
html:not(.no-js) [data-aos^=slide][data-aos^=slide].aos-animate {
  visibility: visible;
  transform: translateZ(0);
}
html:not(.no-js) [data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}
html:not(.no-js) [data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}
html:not(.no-js) [data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}
html:not(.no-js) [data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}
html:not(.no-js) [data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}
html:not(.no-js) [data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}
html:not(.no-js) [data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}
html:not(.no-js) [data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}
html:not(.no-js) [data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}
html:not(.no-js) [data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}
html:not(.no-js) [data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}
html:not(.no-js) [data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}
html:not(.no-js) [data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}
.testimonials {
  background-color: #2f80ed;
  color: #fff;
  min-height: 70vh;
  padding: 80px 0;
}
.testimonials__h2 {
  font-size: 40px;
  line-height: 1.25;
  margin-bottom: 20px;
  text-align: center;
}
@media screen and (min-width: 56em) {
  .testimonials__h2 {
    font-size: 50px;
    margin-bottom: 60px;
  }
}
.testimonials__avatar-wrap {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  overflow: hidden;
  margin: 0 auto 40px;
}
.testimonials__slider-controls {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 2;
}
@media screen and (min-width: 56em) {
  .testimonials__slider-controls {
    z-index: 1;
    left: -50px;
    right: -50px;
  }
}
@media screen and (min-width: 56em) {
  .testimonials__slider-controls {
    left: -150px;
    right: -150px;
  }
}
.testimonials__slider-btn {
  color: #fff;
  font-size: 60px;
  padding: 0;
  position: absolute;
  border: 0;
  background-color: transparent;
  transition: color 0.3s;
}
.testimonials__slider-btn:focus,
.testimonials__slider-btn:hover {
  outline: 0;
  color: #ed217c;
}
.testimonials__slider-btn--left {
  left: 0;
}
.testimonials__slider-btn--right {
  right: 0;
}
.testimonials__slider-wrap {
  max-width: 555px;
  width: 100%;
  margin: 0 auto;
}
.testimonials__slider {
  list-style: none;
  margin: 0;
  padding: 0;
}
.testimonials__slider-item {
  margin: 0;
  padding: 0;
  text-align: center;
}
.testimonials__quote {
  font-size: 20px;
  line-height: 1.5;
  font-style: italic;
  margin-bottom: 20px;
}
.testimonials__quote:before {
  content: '“';
  display: inline-block;
  position: relative;
  left: 5px;
}
.testimonials__quote:after {
  content: '”';
  display: inline-block;
  position: relative;
  right: 5px;
}
@media screen and (min-width: 56em) {
  .testimonials__quote {
    margin-bottom: 60px;
  }
}
.testimonials__name {
  font-weight: bold;
}
.download {
  background-color: #ed217c;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 80px 0;
}
.download__h2 {
  font-size: 40px;
  line-height: 1.25;
  margin-bottom: 20px;
  text-align: center;
}
@media screen and (min-width: 56em) {
  .download__h2 {
    font-size: 50px;
  }
}
.download__desc {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 40px;
  color: rgba(255,255,255,0.8);
  text-align: center;
}
.download__strong {
  font-weight: inherit;
}
.download__desc-wrap {
  order: 2;
}
.download__img-wrap {
  position: relative;
}
.download__img-wrap--left {
  order: 1;
}
.download__img-wrap--right {
  order: 3;
}
.download__img {
  position: absolute;
  display: none;
}
@media screen and (min-width: 56em) {
  .download__img {
    display: block;
    width: 300px;
  }
}
@media screen and (min-width: 70em) {
  .download__img {
    width: 404px;
  }
}
.download__img--left {
  transform: rotate(-35deg);
}
@media screen and (min-width: 56em) {
  .download__img--left {
    left: -160px;
    top: -340px;
  }
}
@media screen and (min-width: 70em) {
  .download__img--left {
    top: -480px;
    left: -260px;
  }
}
.download__img--right {
  transform: rotate(-35deg);
}
@media screen and (min-width: 56em) {
  .download__img--right {
    bottom: -340px;
    right: -160px;
  }
}
@media screen and (min-width: 70em) {
  .download__img--right {
    bottom: -480px;
    right: -260px;
  }
}
.download__form-wrap {
  margin-bottom: 20px;
}
.download__form-control {
  padding-right: 160px;
}
.download__btn {
  position: absolute;
  right: 0;
  top: 0;
  margin: 8px 8px 8px 0;
  width: 135px;
}
.download__form-control-errors {
  color: #fff;
}
.download-links {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}
.download-links__item {
  display: inline-block;
  margin-right: 10px;
}
.download-links__item:last-child {
  margin-right: 0;
}
a.download-links__link {
  font-size: 25px;
  color: rgba(255,255,255,0.6);
}
a.download-links__link:focus {
  color: #fff;
}
a.download-links__link:hover {
  color: #fff;
}
