/*------------------------------------*\
    #ABOUT
\*------------------------------------*/

.app-links-about
  &__item
    display: inline-block
    margin-right: 15px
  &__img
    height: 55px

.slider
  &__controls-wrap
    position: absolute
    z-index: 3
    bottom: 13px
    left: 0
    right: 0
    text-align: center

  &__controls
    display: inline-block
    background: $color-gray-darkest
    border-radius: 10px
    height: 20px
    line-height: 22px
    padding: 0 5px

  &__controls-item
    border: 2px solid $color-gray
    height: 10px
    width: 10px
    border-radius: 10px
    font-size: 0
    padding: 0
    background-color: transparent
    margin: 0 5px 5px
    display: inline-block
    vertical-align: middle
    &:focus,
    &:hover
      background-color: $color-white
      outline: 0

    &.glide__bullet--active
      background-color: $color-pink
      border: 1px solid $color-gray-darkest

.about
  padding-top: 80px
  background-color: $color-violet
  color: $color-white
  min-height: 85vh
  display: flex
  background-image: url(../images/discover-bg.jpg)
  background-position: top center
  background-repeat: no-repeat
  // background-attachment: fixed
  background-size: cover

  // @media screen and (min-width: $breakpoints[0][1])
  &__container
    display: flex
    padding-top : 20px
    @media screen and (min-width: $breakpoints[0][1])
      align-self: flex-end

  &__desc
    align-self: center
    text-align: center
    @media screen and (min-width: $breakpoints[0][1])
      text-align: left

  &__img-cont
    display: none
    @media screen and (min-width: $breakpoints[0][1])
      display: block
      align-self: flex-end
      text-align: center

  &__img-wrap
    position: relative
    float: left
    // transform: translateX(-15px)

  &__img
    display: block

  &__logo-wrap
    // position: absolute
    // right: -45px
    // top: 22%
    // width: 120px
    // height: 120px
    position: relative
    z-index: 2
    align-self: center
    margin: 0 auto

  &__logo-cont
    display: none
    // @media screen and (min-width: $breakpoints[0][1])
    //   display: block
    //   position: absolute
    //   left: 0
    @media screen and (min-width: $breakpoints[1][1])
      display: flex
      position: static

  &__subheader
    color: $color-blue
    font-size: 21px
    line-height: 1.25
    margin-bottom: 10px

  &__h2
    font-size: 40px
    line-height: 1.25
    color: $color-pink
    margin-bottom: 40px
    @media screen and (min-width: $breakpoints[1][1])
      font-size: 56px
      margin-bottom: 60px

  &__slider-wrap
    max-width: 284px
    width: 100%
    margin: 0 auto
    .slider__controls-wrap
      bottom: 30px
    .glide__slide
      max-height : 546px
      overflow : hidden
      img
        max-width:100%

  &__slider-bg
    position: relative
    width: 326px
    height: 550px
    background-image: url(../images/iphone-about.png)
    background-position: top center
    background-repeat: no-repeat
    padding-top: 16px
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 124.8dpi), only screen and (min-resolution: 1.3dppx)
      background-image: url(../images/iphone-about-2x.png)
      background-size: 326px 653px
    &:before
      display: block
      position: absolute
      width: 100%
      left: 0
      right: 0
      z-index: 2
      content: ''
      height: 45px
      background-image: url(../images/iphone-about.png)
      background-repeat: no-repeat
      @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 124.8dpi), only screen and (min-resolution: 1.3dppx)
        background-image: url(../images/iphone-about-2x.png)
        background-size: 326px 653px

    &:before
      top: 0
      background-position: top center

