/*------------------------------------*\
    #DOWNLOAD
\*------------------------------------*/


.download
  background-color: $color-pink
  color: $color-white
  display: flex
  flex-direction: column
  padding: 80px 0

  &__h2
    font-size: 40px
    line-height: 1.25
    margin-bottom: 20px
    text-align: center
    @media screen and (min-width: $breakpoints[1][1])
      font-size: 50px

  &__desc
    font-size: 16px
    line-height: 1.5
    margin-bottom: 40px
    color: rgba($color-white, 0.8)
    text-align: center

  &__strong
    font-weight: inherit

  &__desc-wrap
    order: 2

  &__img-wrap
    position: relative
    &--left
      order: 1

    &--right
      order: 3

  &__img
    position: absolute
    display: none

    @media screen and (min-width: $breakpoints[1][1])
      display: block
      width: 300px
    @media screen and (min-width: $breakpoints[2][1])
      width: 404px
    &--left
      transform: rotate(-35deg)
      @media screen and (min-width: $breakpoints[1][1])
        left: -160px
        top: -340px
      @media screen and (min-width: $breakpoints[2][1])
        top: -480px
        left: -260px
      // @media screen and (min-width: $breakpoints[3][1])
      //   left: -120px

    &--right
      transform: rotate(-35deg)
      @media screen and (min-width: $breakpoints[1][1])
        bottom: -340px
        right: -160px
      @media screen and (min-width: $breakpoints[2][1])
        bottom: -480px
        right: -260px
      // @media screen and (min-width: $breakpoints[3][1])
      //   right: -120px

  &__form-wrap
    margin-bottom: 20px

  &__form-control
    padding-right: 160px

  &__btn
    position: absolute
    right: 0
    top: 0
    margin: 8px 8px 8px 0
    width: 135px

  &__form-control-errors
    color: $color-white

.download-links
  list-style: none
  padding: 0
  margin: 0
  text-align: center

.download-links__item
  display: inline-block
  margin-right: 10px
  &:last-child
    margin-right: 0

a.download-links__link
  font-size: 25px
  color: rgba($color-white, 0.6)
  &:focus
    color: $color-white
  &:hover
    color: $color-white