@keyframes seconds
  0%
    opacity: 1

  100%
    opacity: 0
    position: absolute
    left:-9999px

@keyframes rotate-forever
  0%
    _rotate(0deg)

  100%
    _rotate(360deg)

.loading-spinner
  position: relative
  color:transparent !important
  transition: none !important
  &:after
    content:""
    animation-duration: 0.75s
    animation-iteration-count: infinite
    animation-name: rotate-forever
    animation-timing-function: linear
    height: 16px
    width: 16px
    border: 2px solid $border-loading-spinner
    border-right-color: transparent
    border-radius: 50%
    display: inline-block
    position: absolute
    top: 50%
    left: 50%
    margin: -10px 0 0 -10px


.spinner-body
  overflow: hidden

#spinner-wrapper
  z-index: 999999
  position: fixed
  display: none
  width: 100%
  height: 100%
  background-color: $bg-loader
  &.loading
    display: block


.center-spinner
  display: none
  position: absolute
  min-height: 96px
  height:100%
  width: 100%
  z-index: 999999
  top: 0
  left: 0
  background-color: $bg-loader
  &.loading
    display: block


.line-spinner
  display: none
  position: relative
  height: 96px
  padding: 15px
  &.loading
    display: block

@keyframes rotate
  from
    _rotate(0deg)

  to
    _rotate(360deg)

.loader
  z-index: 2800
  top: 50% !important
  left: 50% !important
  margin-top: -48px !important
  margin-left: -48px !important
  font-size: 14px
  position:absolute
  text-indent: -9999em
  border: 5px solid transparent
  border-bottom-color: $color-loader
  border-top-color: $color-loader
  animation: rotate 1.5s linear 0s infinite

.loader,
.loader:after
  border-radius: 50%
  width: 80px
  height: 80px
