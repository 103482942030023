button
  &:focus
    // outline:0


/*------------------------------------*\
#BUTTON MIXINS & STYLES
\*------------------------------------*/

/**
 * See below for the button colors and sizes
 */

button-variant($color, $bg-color, $bg-color-hover, $bg-color-active, $bg-color-disabled)
  // Change these variables according to UI kit
  // It is similar to a Bootsrap buttons
  color: $color
  background-color: $bg-color
  border: 0

  &:visited
    color: $color

  // Hover and focus (active) styles
  &:hover
    color: $color
    background-color: $bg-color-hover

  &:focus,
  &.focus,
  &:active,
  &.active
    color: $color
    background-color: $bg-color-active


  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled
    background-color: $bg-color-disabled

button-outline-variant($color, $color-hover = white)
  color: $color
  background-color: transparent
  background-image: none
  border-color: $color

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active
    color: $color-hover
    background-color: $color
    border-color: $color

  &:focus,
  &.focus
    box-shadow: 0 0 0 2px rgba($color, 0.5)

  &.disabled,
  &:disabled
    color: $color
    background-color: transparent

// Button sizes
button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius)
  padding: $padding-y $padding-x
  font-size: $font-size
  line-height: $line-height
  border-radius: $border-radius

.btn
  font-family: $font-main
  font-size: 12px
  border-radius: $border-radius
  font-weight: normal
  display:inline-block
  vertical-align: top
  text-decoration: none
  text-align: center
  transition: color .3s, background-color .3s, border-color .3s
  border-radius: 2px
  line-height: 18px
  text-transform: uppercase
  padding: 10px
  // position: relative


/**
 * Button sizes
 */
/* Use only needed */

// .btn-jumbo
//   button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius)
//
// .btn-large
//   button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius)
//
// .btn-small
//   button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius)
//
// .btn-tiny
//   button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius)



/**
 * Button colors
 */
/* Use only needed */

.btn--primary
  button-variant(
    $color-white,
    $color-blue,
    darken($color-blue, 20%),
    $color-pink,
    $color-pink,
  )

// .btn-blue
  // button-variant($color, $background, $border)

// .btn-blue-outline
  // button-outline-variant($color, $color-hover)

/**
 * Button block mixin
 */

.btn--block
  display: block
