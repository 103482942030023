a
  color: $color-link
  text-decoration: underline
  transition: color .3s

  &:visited
    color: $color-link-visited
    text-decoration: underline

  &:hover
    color: $color-link-hover
    text-decoration: none

  &:focus, &:active
    color: $color-link-hover
    text-decoration: none
    outline:none

  &.disabled
    pointer-events: none

a.dotted
  border-bottom:1px dashed $color-link
  text-decoration: none
  &:visited
    border-bottom:1px dashed $color-link-visited
    text-decoration: none

  &:hover
    border-bottom:1px dashed $color-link-hover
    text-decoration: none

  &:focus, &:active
    border-bottom:1px dashed $color-link-active
    text-decoration: none
