placeholder($color = gray)
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="url"],
  input[type="tel"],
  input[type="date"],
  textarea
    &::-webkit-input-placeholder
      color:$color

    &::-moz-placeholder
      color:$color
      opacity: 1

    &:-ms-input-placeholder
      color:$color

    &::placeholder
      color:$color

    &:placeholder
      color:$color

    &:focus
      &::-webkit-input-placeholder
        color:transparent

      &::-moz-placeholder
        color:transparent

      &:-ms-input-placeholder
        color:transparent

      &::placeholder
        color:transparent

      &:placeholder
        color:transparent
