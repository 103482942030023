/*------------------------------------*\
    #HEADER
\*------------------------------------*/

.logo-wrap
  width: 239px
  transition: width 0.2s
  @media screen and (min-width: $breakpoints[1][1])
    margin-right: 20px

  @media screen and (min-width: $breakpoints[2][1])
    margin-right: 50px

.logo
  width: 100%
  transition: all 0.2s

.app-links-header
  visibility: hidden
  opacity: 0
  transition: visibility 0s, opacity 0.5s
  display: flex
  align-self: center
  &__item
    @media screen and (min-width: $breakpoints[1][1])
      margin: 0 15px 0 0
      padding: 0
      &:last-child
        margin-right: 0
  &__img
    @media screen and (min-width: $breakpoints[1][1])
      height: 35px
    @media screen and (min-width: $breakpoints[2][1])
      height: 40px

.menu-list-header
  display: none
  position: relative
  @media screen and (min-width: $breakpoints[1][1])
    text-transform: uppercase
    align-self: center
    display: flex
    flex: 1 1 auto
    list-style: none
    margin 0
    font-size: 12px

.menu-list-header__item
    @media screen and (min-width: $breakpoints[1][1])
      margin-right: 20px
      &:last-child
        margin-right: 0

    @media screen and (min-width: $breakpoints[2][1])
      margin-right: 50px
      &:last-child
        margin-right: 0

a.menu-list-header__link
  color: rgba($color-white, 0.8)
  text-decoration: none
  &:hover
    text-decoration: none
    color: $color-blue

  &:active,
  &:focus,
  &.active
    @media screen and (min-width: $breakpoints[1][1])
      color: $color-pink
      text-decoration: none
      cursor: auto
      &:hover
        text-decoration: none
        color: $color-blue

.header
  height: 80px
  background-color: transparent
  transition: all 0.2s
  z-index: 20
  &--fixed
    height: 60px
    background-color: $color-gray-darkest
    .logo-wrap
      width: 170px
    .app-links
      @media screen and (min-width: $breakpoints[1][1])
        visibility: visible
        opacity: 1