/*------------------------------------*\
    #SIDEBAR
\*------------------------------------*/
.menu
  padding: 30px 15px 15px 15px

.menu-list-side
  margin-bottom: 30px

.menu-list-side__item
  padding: 10px 0
  text-transform: uppercase

a.menu-list-side__link
  color: $color-white
  text-decoration: none
  &:hover
    text-decoration: none
    color: $color-blue

  &:active,
  &:focus,
  &.active
    color: $color-pink
    text-decoration: none
    cursor: auto
    &:hover
      text-decoration: none
      color: $color-blue