/*------------------------------------*\
    #FOOTER
\*------------------------------------*/

.footer
  background-color: $color-gray-darkest
  color: rgba($color-white, 0.8)
  font-size: 14px
  line-height: 1.5

  &__btns
    margin-top:15px

  &__top
    border-bottom: 1px solid rgba($color-gray-lighter, 0.2)
    padding-top: 25px
    padding-bottom: 25px
    text-align: center
    @media screen and (min-width: $breakpoints[1][1])
      text-align: left

  &__bottom
    padding-top: 5px
    padding-bottom: 5px
    text-align: center
    @media screen and (min-width: $breakpoints[1][1])
      text-align: left

  &__logo
    margin-bottom: 10px

  &__h3
    color: $color-white
    text-transform: uppercase
    font-weight: normal
    margin-bottom: 15px

  &__download-cont
    @media screen and (min-width: $breakpoints[1][1])
      padding-left: 30px

  &__item
    margin-bottom: 20px
    &--product
      margin-bottom: 10px
    @media screen and (min-width: $breakpoints[1][1])
      margin-bottom: 0

.menu-list-footer-cont
  @media screen and (min-width: $breakpoints[1][1])
    padding-left: 15px
    padding-right: 15px
    border-left: 1px solid rgba($color-gray-lighter, 0.2)
    border-right: 1px solid rgba($color-gray-lighter, 0.2)

.menu-list-footer
  @media screen and (min-width: $breakpoints[0][1])
    display: flex
    justify-content: center
  @media screen and (min-width: $breakpoints[1][1])
    display: block

.menu-list-footer__item
  margin-bottom: 10px
  @media screen and (min-width: $breakpoints[0][1])
    margin-right: 15px
    &:last-child
      margin-right: 0
  @media screen and (min-width: $breakpoints[1][1])
    margin-right: 0

a.menu-list-footer__link
  text-decoration: none
  color: rgba($color-white, 0.6)
  &:focus
    color: $color-pink
  &:hover
    color: $color-pink
    text-decoration: underline

.app-links-footer
  &__item
    margin-bottom: 15px
  &__img
    max-width: 100%

.footer-links
  list-style: none
  margin: 0
  padding: 0

.footer-links__item
    display: inline-block
    margin-right: 40px
    &:last-child
      margin-right: 0

a.footer-links__link
    text-decoration: none
    color: rgba($color-white, 0.8)
    &:focus
      color: $color-pink
    &:hover
      color: $color-pink
      text-decoration: underline
