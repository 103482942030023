/* Base. */
*
  box-sizing: border-box

html
  overflow-x: hidden
  overflow-y: $overflowY
  margin: 0 !important
  -webkit-font-smoothing: antialiased;

html, body
  height: 100%

body
  line-height: 1
  background-color: $body-bg-color
  background-image: $body-bg-img
  background-repeat: $body-bg-repeat
  background-position: $body-bg-position
  background-attachment: $body-bg-attachment
  color: $color-text
  text-align: left
  font-size: $rhythmic-fontsize
  font-family: $font-main
  font-weight: normal
  overflow-x: hidden !important

*
  box-sizing: border-box

label, input[type="button"], input[type="submit"], button
  cursor: pointer

blockquote, q
  quotes: none

blockquote:before, blockquote:after,
q:before, q:after
  content: ''
  content: none

/* /Base */
