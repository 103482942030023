/*
* Snippet for vertical align for page content.
* Use it like this:
*
* <main class="main vertical-outer">
*   <div class="vertical-inner">
*     <div class="container">
*       ...
*     </div>
*   </div>
* </main>
*/

.vertical-outer
  display: flex
  flex-direction: column
  justify-content: center
  flex: 1 0 auto

// .vertical-inner
