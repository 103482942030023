_rotate(degrees)
  transform s("rotate(%s)", degrees)

_scale(ratio)
  transform s("scale(%s)", ratio)


_translate(x, y)
  transform s("translate(%s, %s)", x, y)

_skew(x, y)
  transform s("skew(%s, %s)", x, y)

_translate3d(x, y, z)
  transform s("translate3d(%s, %s, %s)", x, y, z)
