.visibility
  opacity: 0

.animated
  animation-duration: 1s
  animation-fill-mode: both

  @media (print), (prefers-reduced-motion)
    animation: unset !important
    transition: none !important

  &.infinite
    animation-iteration-count: infinite
  &.delay-2s
    animation-delay: 2s

  &.delay-02s
    animation-delay: 0.2s
  &.delay-04s
    animation-delay: 0.4s

@keyframes slideInLeft
  from
    transform: translate3d(-1000%, 0, 0)
    visibility: visible

  to
    transform: translate3d(0, 0, 0)

@keyframes slideInRight
  from
    transform: translate3d(500%, 0, 0)
    visibility: visible

  to
    transform: translate3d(0, 0, 0)


@keyframes slideInUp
  from
    transform: translate3d(0, 400%, 0)
    visibility: visible

  to
    transform: translate3d(0, 0, 0)


@keyframes fadeInUp
  from
    opacity: 0
    transform: translate3d(0, 100%, 0)

  to
    opacity: 1
    transform: translate3d(0, 0, 0)


@keyframes fadeInLeft
  from
    opacity: 0
    transform: translate3d(-100%, 0, 0)

  to
    opacity: 1
    transform: translate3d(0, 0, 0)

@keyframes fadeInRight
  from
    opacity: 0
    transform: translate3d(100%, 0, 0)

  to
    opacity: 1
    transform: translate3d(0, 0, 0)

.fade-in-up
  animation-name: fadeInUp

.slide-in-up
  animation-name: slideInUp

.slide-in-left
  animation-name: slideInLeft

.slide-in-right
  animation-name: slideInRight

.fade-in-left
  animation-name: fadeInLeft

.fade-in-right
  animation-name: fadeInRight

