.embed
  display: block
  height: 0
  margin: ($gutterWidth/2) 0
  overflow: hidden
  padding: 0
  position: relative

  iframe,
  embed,
  object,
  video
    border: 0
    bottom: 0
    height: 100% !important
    left: 0
    position: absolute
    top: 0
    width: 100% !important


.embed-16-9
  padding-bottom: 56.25% /* 16:9 */

.embed-4-3
  padding-bottom: 75% /* 4:3 */
