/*------------------------------------*\
    #COMMON WRAPPERS
\*------------------------------------*/

.panel
  height: 100%
  position: relative
  left: 0
  margin-right: 0
  align-items: stretch
  display: flex
  flex-direction: column
  min-height: 100%
  position: relative
  overflow-x: hidden

.main
  flex: 1 0 auto
  width: 100%
  overflow: $overflowMain
  position: relative
  background-color: $color-white
  &:after
    content: "\00a0" // &nbsp
    display: block
    height: 0px
    visibility: hidden

input[type="checkbox"].nav-trigger
  position: fixed
  left: -999px
  // clip: rect(0, 0, 0, 0)
  & + label
    position: fixed
    right: 0
    top: 0
    z-index: 21
    // padding: 0
    display: inline-block
    vertical-align: middle
    text-align: center
    cursor: pointer

/* Make the Magic Happen */
.panel,
header
  transition: left 0.2s, margin-right 0.2s

header
  padding-right: 50px
  @media screen and (min-width: $breakpoints[1][1])
    padding-right: 0

input[type="checkbox"].nav-trigger
  &:checked + label
    right: 0

  &:checked ~ .panel
    left: 256px
    header
      left: 256px

  &:checked ~ nav.menu
    left: 0

  @media screen and (min-width: $breakpoints[1][1])
    &:checked + label,
    & + label
      z-index: -9999

    &:checked ~ .panel,
    & ~ .panel
      left: 0
      margin-right: 0
      header
        left: 0
        padding-right: 0



/*------------------------------------*\
    #HEADER
\*------------------------------------*/

.header
  position: fixed
  top: 0
  left: 0
  right: 0
  width: 100%
  z-index: 10
  flex: none
  padding-top: 5px
  padding-bottom: 5px

.header-container
  position: relative
  z-index: 3
  @media screen and (min-width: $breakpoints[1][1])
    display: flex

.btn-burger
  padding: 12px
  display: inline-block
  cursor: pointer
  transition-property: opacity, filter, left
  transition-duration: 0.2s
  transition-timing-function: linear
  font: inherit
  color: inherit
  text-transform: none
  background-color: transparent
  border: 0
  margin: 0
  overflow: visible
  &:hover
    opacity: 0.7

  @media screen and (min-width: $breakpoints[1][1])
    display: none

input[type="checkbox"].nav-trigger
  &:checked + .btn-burger
    &:hover
      opacity: 0.7
    .btn-burger-inner,
    .btn-burger-inner::before,
    .btn-burger-inner::after
      background-color: $color-white

    .btn-burger-inner
      transform: rotate(90deg)
      background-color: transparent !important
      transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear
    .btn-burger-inner::before
      top: 0
      transform: rotate(-45deg)
      transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1)
    .btn-burger-inner::after
      bottom: 0
      transform: rotate(45deg)
      transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1)


.btn-burger-box
  width: 35px
  height: 35px
  display: inline-block
  position: relative

.btn-burger-inner
  display: block
  top: 50%
  margin-top: -2px
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear

  &::before
    top: -10px
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19)
  &::after
    bottom: -10px
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19)

.btn-burger-inner,
.btn-burger-inner::before,
.btn-burger-inner::after
  width: 35px
  height: 4px
  background-color: $color-white
  border-radius: 4px
  position: absolute
  transition-property: transform
  transition-duration: 0.15s
  transition-timing-function: ease

.btn-burger-inner::before,
.btn-burger-inner::after
  content: ""
  display: block

/*------------------------------------*\
    #NAVIGATION
\*------------------------------------*/

.menu-list
  list-style: none
  &__item
    display: block
    padding: 0
    margin: 0

  &__link
    &--active,
    &:hover
      color: inherit

    &--active
      text-decoration: none
      cursor:auto

.menu
  width: 256px
  height: 100%
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: -256px
  z-index: 0
  -webkit-overflow-scrolling: touch
  display: flex
  flex-direction: column
  overflow-y: auto
  background-color: $color-gray-darkest
  padding: $gutterWidth/2
  transition: left all .2s

// @media screen and (min-width: $breakpoints[1][1])
//   .menu
//     width: 296px
//     z-index: 1

.app-links
  list-style: none
  margin: 0
  padding: 0
  &__link
    &:focus,
    &:hover
      opacity: 0.7


/*------------------------------------*\
    #FOOTER
\*------------------------------------*/

.footer
  flex: none
