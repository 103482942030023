/*------------------------------------*\
    #TESTIMONIALS
\*------------------------------------*/


.testimonials
  background-color: $color-blue
  color: $color-white
  min-height: 70vh
  padding: 80px 0

  &__h2
    font-size: 40px
    line-height: 1.25
    margin-bottom: 20px
    text-align: center
    @media screen and (min-width: $breakpoints[1][1])
      font-size: 50px
      margin-bottom: 60px

  &__avatar-wrap
    border-radius: 50%
    width: 120px
    height: 120px
    overflow: hidden
    margin: 0 auto 40px

  &__slider-controls
    position: absolute
    top: 50%
    left: 0
    right: 0
    z-index: 2
    @media screen and (min-width: $breakpoints[1][1])
      z-index: 1
      left: -50px
      right: -50px
    @media screen and (min-width: $breakpoints[1][1])
      left: -150px
      right: -150px

  &__slider-btn
    color: $color-white
    font-size: 60px
    padding: 0
    position: absolute
    border: 0
    background-color: transparent
    transition: color 0.3s
    &:focus,
    &:hover
      outline: 0
      color: $color-pink

  &__slider-btn--left
    left: 0

  &__slider-btn--right
    right: 0

  &__slider-wrap
    max-width: 555px
    width: 100%
    margin: 0 auto

  &__slider
    list-style: none
    margin: 0
    padding: 0

  &__slider-item
    margin: 0
    padding: 0
    text-align: center

  &__quote
    font-size: 20px
    line-height: 1.5
    font-style: italic
    margin-bottom: 20px
    &:before
      content: '“'
      display: inline-block
      position: relative
      left: 5px
    &:after
      content: '”'
      display: inline-block
      position: relative
      right: 5px
    @media screen and (min-width: $breakpoints[1][1])
      margin-bottom: 60px

  &__name
    font-weight: bold