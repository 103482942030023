@font-face
  font-family: "icons"
  src: url('../fonts/icons.eot')
  src: url('../fonts/icons.eot?#iefix') format('eot'),
    url('../fonts/icons.woff2') format('woff2'),
    url('../fonts/icons.woff') format('woff'),
    url('../fonts/icons.ttf') format('truetype'),
    url('../fonts/icons.svg#icons') format('svg')
  font-weight: normal
  font-style: normal

.icon
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important
  speak: none
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1
  display: inline-block

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.icon-2x
  font-size: 2rem

.icon-3x
  font-size: 3em

.icon-4x
  font-size: 4em

.icon-5x
  font-size: 5em

.icon-android:before
  content: "\EA01"

.icon-apple:before
  content: "\EA02"

.icon-chevron-left:before
  content: "\EA03"

.icon-chevron-right:before
  content: "\EA04"

.icon-comments:before
  content: "\EA05"

.icon-facebook:before
  content: "\EA06"

.icon-feed-list:before
  content: "\EA07"

.icon-feed:before
  content: "\EA08"

.icon-inbox:before
  content: "\EA09"

.icon-instagram:before
  content: "\EA0A"

.icon-like:before
  content: "\EA0B"

.icon-profile:before
  content: "\EA0C"

.icon-radar:before
  content: "\EA0D"

.icon-twitter:before
  content: "\EA0E"

.icon-user:before
  content: "\EA0F"
