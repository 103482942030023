@import './variables.styl'

//
// -- Start editing -- //
//
// Set the number of columns you want to use on your layout.
$grid-columns = $columns // 12
// Set the gutter between columns.
$gutter-width = $gutterWidth // 1rem
// Set a margin for the container sides.
$outer-margin = $gutterWidth
// Create or remove breakpoints for your project
// Syntax:
// name SIZErem,
$grid-breakpoints = $breakpoints
// $grid-max-width = 1200px
$grid-max-width = none
//
// -- Stop editing -- //
//
$gutter-compensation = $gutter-width * 0.5 * -1
$half-gutter-width = $gutter-width * 0.5

.wrapper
  box-sizing: border-box
  max-width: $grid-max-width
  margin: 0 auto

.container-fluid
  margin-right: auto
  margin-left: auto
  padding-right: $outer-margin
  padding-left: $outer-margin
  max-width: 1200px

.row
  box-sizing: border-box
  display: flex
  flex: 0 1 auto
  flex-direction: row
  flex-wrap: wrap
  margin-right: $gutter-compensation
  margin-left: $gutter-compensation

.row.reverse
  flex-direction: row-reverse

.col.reverse
  flex-direction: column-reverse

flexboxgrid-col-common()
  box-sizing: border-box
  flex-grow: 0
  flex-shrink: 0
  padding-right: $half-gutter-width
  padding-left: $half-gutter-width

// $name = xs

.col
  flexboxgrid-col-common()
  flex-basis: auto

for $i in (1)..($grid-columns)
  .col-{$i}
    flexboxgrid-col-common()
    flex-basis: (100% / $grid-columns * $i)
    max-width: (100% / $grid-columns * $i)

for $i in (0)..($grid-columns)
  .col-offset-{$i}
    flexboxgrid-col-common()
    if $i == 0
      margin-left: 0
    else
      margin-left: (100% / $grid-columns * $i)

.col
  flex-grow: 1
  flex-basis: 0
  max-width: 100%

.col-start
  justify-content: flex-start
  text-align: left

.col-center
  justify-content: center
  text-align: center

.col-end
  justify-content: flex-end
  text-align: right

.col-top
  align-items: flex-start

.col-middle
  align-items: center

.col-bottom
  align-items: flex-end

.col-around
  justify-content: space-around

.col-between
  justify-content: space-between

.col-first
  order: -1

.col-last
  order: 1

.col-auto
  flexboxgrid-col-common()
  flex-basis: auto
  width: auto
  max-width: none

for $breakpoint in $grid-breakpoints
  $name = $breakpoint[0]
  $size = $breakpoint[1]
  $container = $breakpoint[2]

  @media only screen and (min-width: $size)
    .container
      width: $container

    .col-{$name}
      flexboxgrid-col-common()
      flex-basis: auto

    for $i in (1)..($grid-columns)
      .col-{$name}-{$i}
        flexboxgrid-col-common()
        flex-basis: (100% / $grid-columns * $i)
        max-width: (100% / $grid-columns * $i)

    for $i in (0)..($grid-columns)
      .col-{$name}-offset-{$i}
        flexboxgrid-col-common()
        if $i == 0
          margin-left: 0
        else
          margin-left: (100% / $grid-columns * $i)

    .col-{$name}-auto
      flexboxgrid-col-common()
      flex-basis: auto
      width: auto
      max-width: none

    .col-{$name}
      flex-grow: 1
      flex-basis: 0
      max-width: 100%

    .col-start-{$name}
      justify-content: flex-start
      text-align: left

    .col-center-{$name}
      justify-content: center
      text-align: center

    .col-end-{$name}
      justify-content: flex-end
      text-align: right

    .col-top-{$name}
      align-items: flex-start

    .col-middle-{$name}
      align-items: center

    .col-bottom-{$name}
      align-items: flex-end

    .col-around-{$name}
      justify-content: space-around

    .col-between-{$name}
      justify-content: space-between

    .col-first-{$name}
      order: -1

    .col-last-{$name}
      order: 1