/* Reset. DO NOT TOUCH ANYTHING. */

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, abbr, acronym, address, big, cite, code, del, dfn, em, font,
img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i,
center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption,
figure, footer, header, hgroup, menu, nav, section, summary, time, mark,
audio, video
  margin: 0
  padding: 0
  border: 0
  outline: 0
  vertical-align: baseline
  background-color: transparent
  font-size: 100%

html
  line-height: 1.15
  -ms-text-size-adjust: 100%
  -webkit-text-size-adjust: 100%

a
  margin:0
  padding:0
  font-size:100%
  vertical-align:baseline
  background-color: transparent
  -webkit-text-decoration-skip: objects

a:active,
a:hover
  outline: 0

a:focus
  outline: thin dotted

table
  border-collapse: collapse
  border-spacing: 0

td, td img
  vertical-align: top

input, select, button, textarea
  margin: 0
  font-size: 100%

button,
select
  text-transform: none

input[type="text"], input[type="password"], textarea
  padding: 0

/**
* 1. Address box sizing set to `content-box` in IE 8/9/10.
* 2. Remove excess padding in IE 8/9/10.
*/

input[type="checkbox"],
input[type="radio"]
  vertical-align: middle
  margin: 0
  padding: 0
  box-sizing: border-box /* 1 */
  padding: 0 /* 2 */

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"]
  -webkit-appearance: button /* 2 */
  cursor: pointer /* 3 */

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled]
  cursor: default

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"]
  -webkit-appearance: textfield /* 1 */
  -moz-box-sizing: content-box
  -webkit-box-sizing: content-box /* 2 */
  box-sizing: content-box

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration
  -webkit-appearance: none

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button
  height: auto

/**
 * Remove inner padding and border in Firefox 4+.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner
  border-style: none
  padding: 0
  outline: 1px dotted ButtonText

::-webkit-file-upload-button
  -webkit-appearance: button /* 1 */
  font: inherit /* 2 */

/**
 * 1. Remove default vertical scrollbar in IE 8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea
  overflow: auto /* 1 */
  vertical-align: top /* 2 */

sub,
sup
  font-size: 75%
  line-height: 0
  position: relative
  vertical-align: baseline

sub
  bottom: -0.25em

sup
  top: -0.5em

ins
  text-decoration: none

del
  text-decoration: line-through

article, aside, details, figcaption, figure, footer, header, hgroup, menu,
nav, section
  display:block

/*
 * Add the correct display in all browsers.
 */

summary
  display: list-item

audio, canvas, video
  display: inline-block

audio:not([controls])
  display: none
  height: 0

[hidden],
template
  display: none

nav ul
  list-style:none

svg:not(:root)
  overflow: hidden

hr
  -moz-box-sizing: content-box
  box-sizing: content-box
  height: 0

pre,
code,
kbd,
samp
  font-family: monospace, monospace
  font-size: 1em

abbr[title]
  border-bottom: none
  text-decoration: underline
  text-decoration: underline dotted

b,
strong
  font-weight: inherit

b,
strong
  font-weight: bolder

dfn
  font-style: italic

small
  font-size: 80%

legend
  box-sizing: border-box
  color: inherit
  display: table
  max-width: 100%
  padding: 0
  white-space: normal

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress
  display: inline-block
  vertical-align: baseline

/* /Reset */
