.popup
  width: 100%
  margin: 0 auto
  position: relative
  background-color #fff

.popup--small
    max-width: 400px

.popup--middle
    max-width: $screenTablet

.popup--large
    max-width: $screenLarge


.popup-content
  padding: ($gutterWidth/2) ($gutterWidth/2) ($gutterWidth/2) ($gutterWidth/2)
  clearfix()

// .popup-header
//
// .popup-title
//
// .popup-body
//
// .popup-footer
