/*------------------------------------*\
    #FEATURES
\*------------------------------------*/


.app-links-features
  text-align: center
  &__item
    display: inline-block
    margin: 0 10px 10px

.features
  background-color: $color-gray-darkest
  color: $color-white
  padding: 80px 0

  &__track .glide__slide
    img
      max-width: 100%

  &__container
    margin-bottom: 40px
    @media screen and (min-width: $breakpoints[1][1])
      justify-content: flex-end

  &__h2
    font-size: 40px
    line-height: 1.25
    text-align: center
    margin-bottom: 40px
    @media screen and (min-width: $breakpoints[1][1])
      font-size: 50px
      margin-bottom: 80px

  &__h3
    font-size: 28px
    line-height: 1.5
    margin-bottom: 25px
    text-align: center

  &__desc
    text-align: center
    font-size: 14px
    line-height: 1.5
    margin-bottom: 40px
    max-width: 790px
    margin: 0 auto 20px

  &__strong
    font-weight: inherit

  &__img-cont
    display: none
    @media screen and (min-width: $breakpoints[1][1])
      display: block
      order: 1
      position: relative
    @media screen and (min-width: $breakpoints[2][1])
      order: 2

  &__slider-wrap
    max-width: 310px
    width: 100%
    margin: 0 auto

  &__slider-bg
    position: relative
    width: 356px
    height: 713px
    background-image: url(../images/iphone.png)
    background-position: top center
    background-repeat: no-repeat
    padding-top: 20px
    @media screen and (min-width: $breakpoints[1][1])
      position: absolute
      right: 0
    @media screen and (min-width: $breakpoints[2][1])
      //position: static
      position: relative
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 124.8dpi), only screen and (min-resolution: 1.3dppx)
      background-image: url(../images/iphone-2x.png)
      background-size: 356px 713px
    &:before
    &:after
      display: block
      position: absolute
      width: 100%
      left: 0
      right: 0
      z-index: 2
      content: ''
      height: 50px
      background-image: url(../images/iphone.png)
      background-repeat: no-repeat
      @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 124.8dpi), only screen and (min-resolution: 1.3dppx)
        background-image: url(../images/iphone-2x.png)
        background-size: 356px 713px

    &:before
      top: 0
      background-position: top center

    &:after
      bottom: 0
      background-position: bottom center

.features-list
  list-style: none
  display: flex
  flex-direction: column
  align-items: stretch
  justify-content: center
  position: relative
  z-index: 10
  &--left
    @media screen and (min-width: $breakpoints[1][1])
      order: 2
      .features-list__item
        padding-left: 20px

    @media screen and (min-width: $breakpoints[2][1])
      order: 1
      .features-list__item
        padding-left: 0
        padding-right: 20px
      .features-list__btn
        text-align: right
      .features-list__desc-wrap
        order: 1
      .features-list__icon-wrap
        order: 2

  &--right
    @media screen and (min-width: $breakpoints[1][1])
      order: 3
      .features-list__item
        padding-left: 20px

      .features-list__btn
        &:before
          left: -55px
          right: auto
        &:after
          left: -60px
          right: auto

  &__item
    padding: 0
    margin: 0 0 10px 0
    flex-grow: 1
    flex-shrink: 1
    flex-basis: auto

  &__btn
    padding: 0
    margin: 0
    border: 0
    display: block
    width: 100%
    transition: background-color 0.2s
    background-color: transparent
    font-family: $font-openSans
    text-align: left
    &:focus,
    &:hover
      outline: 0
      background-color: rgba($color-blue, 0.6)
      .features-list__icon-wrap,
      .features-list__desc-wrap
        color: $color-white
    &.active
      background-color: $color-blue
      @media screen and (min-width: $breakpoints[2][1])
        position: relative
        &:after,
        &:before
          content: ''
          position: absolute
          display: block
          background-color: $color-blue
        &:before
          width: 55px
          height: 1px
          right: -55px
          top: 55px
        &:after
          width: 16px
          height: 16px
          border-radius: 50%
          right: -60px
          top: 48px

      .features-list__icon-wrap,
      .features-list__desc-wrap
        color: $color-white

  &__btn-data
    padding: 20px 13px
    display: flex

  &__icon-wrap
    font-size: 33px
    color: rgba($color-white, 0.8)
    flex: 0 0 41px

  &__desc-wrap
    color: rgba($color-white, 0.8)
    min-width: 50%

  &__h3
    color: $color-white
    font-size: 21px
    line-height: 1.5
    margin-bottom: 20px


  &__desc
    line-height: 1.5
