/*------------------------------------*\
    #HOW IT WORKS
\*------------------------------------*/


.how-it-works
  background-color: $color-pink
  color: $color-white
  min-height: 80vh
  display: flex
  flex-direction: column
  padding-top: 80px

  &__container
    display: flex
    flex-direction: column

  &__desc
    font-size: 16px
    line-height: 1.5
    margin-bottom: 40px

  &__strong
    font-weight: inherit

  &__h2
    font-size: 40px
    line-height: 1.25
    margin-bottom: 20px
    text-align: center
    @media screen and (min-width: $breakpoints[1][1])
      text-align: left
      font-size: 50px

  &__h3
    font-size: 21px
    line-height: 1.5
    margin-bottom: 25px

  &__list
    counter-reset: my-awesome-counter
    list-style: none
    padding-left: 40px

  &__list-item
    margin-bottom: 35px
    font-size: 14px
    line-height: 1.5
    counter-increment: my-awesome-counter
    position: relative
    padding-left: 55px
    &:before
      content: counter(my-awesome-counter)
      color: $color-white
      font-size: 16px
      position: absolute
      left: 0
      background: $color-blue
      border-radius: 50%
      width: 38px
      height: 38px
      text-align: center
      line-height: 38px

  &__desc-cont
    align-self: center

  &__img-cont
    display: none
    @media screen and (min-width: $breakpoints[0][1])
      display: block
      align-self: flex-end

  &__img-wrap
    position: relative
    float: left
    // transform: translateX(100px)
    // @media screen and (min-width: $breakpoints[1][1])
    //   transform: translateX(0px)

  &__img
    display: block

  &__slider-wrap
    max-width: 390px
    width: 100%
    margin: 0 auto
    .slider__controls-wrap
      bottom: 40px
    .glide__track
      background: #302f31
    .glide__slide
      max-height : 863px
      overflow : hidden
      text-align : center
      img
        max-width:100%

  &__slider-bg
    position: relative
    width: 450px
    height: 845px
    background-image: url(../images/iphone-how-it-works.png)
    background-position: top center
    background-repeat: no-repeat
    padding-top: 16px
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 124.8dpi), only screen and (min-resolution: 1.3dppx)
      //background-image: url(../images/iphone-how-it-works-2x.png)
      background-size: 450px 898px
    &:before
      display: block
      position: absolute
      width: 100%
      left: 0
      right: 0
      z-index: 3
      content: ''
      height: 65px
      background-image: url(../images/iphone-how-it-works.png)
      background-repeat: no-repeat
      @media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 124.8dpi), only screen and (min-resolution: 1.3dppx)
        //background-image: url(../images/iphone-how-it-works-2x.png)
        background-size: 450px 898px

    &:before
      top: 0
      background-position: top center